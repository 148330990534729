import React from 'react';
import { PageProps, graphql } from 'gatsby';

import Layout from '../components/layout/Layout';
import MetaData from '../components/seo/MetaData';
import LatestRecipes from '../components/latestrecipes/LatestRecipes';
import RecipesByCategory from '../components/recipe/RecipesByCategory';
import WideContainer from '../components/layout/WideContainer';
import VegetarDk from '../components/landingpage/VegetarDk';
import Header from '../components/header/Header';
import OrganizationStructuredData from '../components/seo/OrganizationStructuredData';
import Retter from '../components/landingpage/Retter';
import HvorforVegetar from '../components/landingpage/HvorforVegetar';
import SendYourRecipes from '../components/calltoaction/SendYourRecipes';

interface Props extends PageProps {
  data: {
    allStoryblokEntry: {
      edges: any;
    };
  };
}

const Home: React.FunctionComponent<Props> = ({ data }) => {
  return (
    <Layout>
      <MetaData 
        title="Vegetariske Opskrifter og Retter"
      />
      <OrganizationStructuredData />
      <Header data={data} />
      <RecipesByCategory />
      <WideContainer>
         <LatestRecipes data={data} />
      </WideContainer>
      <VegetarDk />
      <Retter />
      <HvorforVegetar />
      <SendYourRecipes />
    </Layout>
  );
};

export const query = graphql`
query StoryblokQuery{
  allStoryblokEntry(filter: {field_component: {eq: "Post"}})  {
            edges {
              node {
                id
                name
                created_at
                uuid
                slug
                full_slug
                content
                is_startpage
                parent_id
                group_id
              }
            }
          }
}`

export default Home;
