import * as React from 'react';
import Tagliatelle from '../images/Tagliatelle';

const Retter: React.FunctionComponent = () => {
    return (
        <div className="mt-6 mb-6 md:mt-16 md:mb-16 theme-border-bottom theme-border-top">
            <div className="grid grid-cols-1 md:grid-cols-2">
                <div>
                    <Tagliatelle />
                </div>
                <div className="p-4 md:p-16 light-theme-background vertical-center">
                    <div>
                        <h2 className="mb-4">Retter for en enhver vegetar</h2>
                        <hr className="w-48 mb-4 border-2" />
                        <p className="text-gray-900 mt-1 mb-3">
                            Mange føler, at det er en begrænsing er arbejde vegetarisk i køkkenet.
                            Det behøver det dog slet ikke at være. Der er uendeligt mange opskrifter, som du kan lave uden kød.
                            Det handler blot om at finde den rigtig inspiration til retterne og lære dine råvarer godt at kende.
                        </p>
                        <p className="text-gray-900 mt-1 mb-3">
                            Der er mange forskellige retter, der ikke kræver det store fra kokken. Du skal derfor ikke være bange for at udskifte dit hverdagsmad med vegetariske retter.
                            Kig dig omkring herinde eller læs beretninger fra kokke, som har skiftet til det vegetariske køkken.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Retter;