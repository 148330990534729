import React from 'react';
import CustomCard from '../cards/CustomCard';
import WideContainer from '../layout/WideContainer';
import Fruitbowl from "../images/icons/fruitbowl.png";
import Lettuce from "../images/icons/lettuce.png";
import Eggplant from "../images/icons/eggplant.png";
import Grape from "../images/icons/grape.png";
import Cucumber from "../images/icons/cucumber.png";
import Cherry from "../images/icons/cherry.png";

import LightThemeContainer from '../layout/LightThemeContainer';
import { Link } from 'gatsby';
import Routes from '../../constants/Routes';

const RecipesByCategory: React.FunctionComponent = () => {
  
    return (
        <LightThemeContainer className="mt-0 mb-8">
            <WideContainer>
                <h2 className="mb-6">Kategorier af opskrifter</h2>
                <div className="grid grid-cols-2 lg:grid-cols-6">
                        <Link to={Routes.HOVEDRET}>
                            <CustomCard className="text-center pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
                                <img 
                                    src={Fruitbowl}
                                    className="mt-0"
                                    style={{ borderRadius: 10 }}
                                    alt="Hovedret"
                                />
                                <h4 className="mt-0">
                                    Hovedret
                                </h4>
                            </CustomCard>
                        </Link>
                        <Link to={Routes.FORRET}>
                            <CustomCard className="text-center pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-0 ml-2 md:ml-0">
                                <img 
                                    src={Eggplant}
                                    className="mt-0"
                                    style={{ borderRadius: 10 }}
                                    alt="Forret"
                                />
                                <h4 className="mt-0">
                                    Forret
                                </h4>
                            </CustomCard>
                        </Link>
                        <Link to={Routes.SALAT}>
                            <CustomCard className="text-center pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
                                <img 
                                    src={Lettuce}
                                    className="mt-0"
                                    style={{ borderRadius: 10 }}
                                    alt="Salat"
                                />
                                <h4 className="mt-0">
                                    Salat
                                </h4>
                            </CustomCard>
                        </Link>
                        <Link to={Routes.DESSERT}>
                            <CustomCard className="text-center pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-0 ml-2 md:ml-0">
                                <img 
                                    src={Grape}
                                    className="mt-0"
                                    style={{ borderRadius: 10 }}
                                    alt="Dessert"
                                />
                                <h4 className="mt-0">
                                    Dessert
                                </h4>
                            </CustomCard>
                        </Link>
                        <Link to={Routes.TILBEHØR}>
                            <CustomCard className="text-center pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
                                <img 
                                    src={Cucumber}
                                    className="mt-0"
                                    style={{ borderRadius: 10 }}
                                    alt="Tilbehør"
                                />
                                <h4 className="mt-0">
                                    Tilbehør
                                </h4>
                            </CustomCard>
                        </Link>
                        <Link to={Routes.DRINKS}>
                            <CustomCard className="text-center pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-0 ml-2 md:ml-0">
                                <img 
                                    src={Cherry}
                                    className="mt-0"
                                    style={{ borderRadius: 10 }}
                                    alt="Tilbehør"
                                />
                                <h4 className="mt-0">
                                    Drinks
                                </h4>
                            </CustomCard>
                        </Link>
                </div>
            </WideContainer>
        </LightThemeContainer>
    )
};

export default RecipesByCategory;