import React from 'react';
import { Link } from 'gatsby';
import Routes from '../../constants/Routes';
import { isMobile } from 'react-device-detect';
import { RecipeModel } from '../../models/RecipeModel';
import { StoryblokModel } from '../../models/StoryblokModel';
import {ImageResizeService} from '../../services/ImageTransformationService';
import Author from '../recipe/Author';
import { Helpers } from '../../services/Helpers';

interface ComponentProps {
  data: {
    allStoryblokEntry: {
      edges: Array<StoryblokModel>;
    };
  };
}

const Header: React.FunctionComponent<ComponentProps> = ({
  data
}) => {
  const recipeData: Array<StoryblokModel> = data.allStoryblokEntry.edges;

  recipeData.sort(function(a,b){
    return new Date(b.node.created_at).getTime() - new Date(a.node.created_at).getTime();
  });

  const recipe: Array<RecipeModel> = recipeData.map(edge => JSON.parse(edge.node.content));

  return (
    <div>
        <div className="flex flex-wrap vertical-center">
          <div className="w-full md:w-3/5 h-auto">
            <Link to={`/${Routes.RECIPEPREFIX}/${recipeData[0].node.slug}/`} title={recipe[0].headline}>
              <img src={ImageResizeService.imageResize(recipe[0].image.filename, "1920x1080")} alt={recipe[0].image.name} />
            </Link>
          </div>
          <div className="w-full md:w-2/5 h-auto text-center pt-6 md:pt-0 pb-6 md:pb-0">
            <div className="md:p-24 sm:p-6">
              <Link to={`/${Routes.RECIPEPREFIX}/${recipeData[0].node.slug}/`} title={recipe[0].headline}>
                  <span className="text-gray-800 small-headline">{recipe[0].category}</span>
                  <div>
                    <p className="inline-block text-sm mr-2 mt-0 mb-0">{recipe[0].cook_time} min</p>
                    <span className="inline-block text-lg dark-theme-color mt-0 mb-0">•</span>
                    <p className="inline-block text-sm text-gray-800 ml-2 mt-0 mb-0">{recipe[0].servings} personer</p>
                  </div>
                  <h1 className="underline-color hover:underline mt-2">{recipe[0].headline}</h1>
              </Link>
              <Author
                name={recipe[0].author.content.author_name}
                image={recipe[0].author.content.author_image[0].filename}
                instagramLink={recipe[0].author.content.author_instagram_url}
                author_slug={recipe[0].author.slug}
              />
            </div>
          </div>
        </div>
    </div>
  );
};

Header.defaultProps = {
  className: ``,
} as Partial<ComponentProps>;

export default Header;
