import * as React from 'react';
import RecipeCard from '../cards/RecipeCard';
import { RecipeModel } from '../../models/RecipeModel';
import { StoryblokModel } from '../../models/StoryblokModel';
import Routes from '../../constants/Routes';

interface Props {
  data: {
    allStoryblokEntry: {
      edges: any;
    };
  };
}

const LatestRecipes: React.FunctionComponent<Props> = ({ data }) => {
    const recipeData: Array<StoryblokModel> = data.allStoryblokEntry.edges;
    
    recipeData.sort(function(a,b){
      return new Date(b.node.created_at).getTime() - new Date(a.node.created_at).getTime();
    });
    
    const recipes: Array<RecipeModel> = recipeData.map(edge => JSON.parse(edge.node.content));

    return (
        <div className="mt-12 mb-12">
            <h2 className="mb-6 mt-8">Seneste opskrifter</h2>
            <div className="grid grid-cols-1 md:grid-cols-4">
                {recipes.slice(1, 9).map((item: RecipeModel, index: number) =>
                    <div key={index}>
                        <RecipeCard 
                            title={item.headline}
                            image={item.hasOwnProperty("image")? item.image.filename : "null"}
                            imageAlt={item.hasOwnProperty("image")?item.image.name || item.image.filename : "null"}
                            authorImage={item.hasOwnProperty("author")? item.author.content.author_image[0].filename : "null"}
                            authorName={item.hasOwnProperty("author")? item.author.content.author_name : "null"}
                            authorInstagramLink={item.hasOwnProperty("author")?item.author.content.author_instagram_url : "null"}
                            link={`/${Routes.RECIPEPREFIX}/${recipeData[index + 1].node.slug}/`}
                            author_slug={item.hasOwnProperty("author")? item.author.slug : "null"}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default LatestRecipes;