import * as React from 'react';
import Container from '../layout/Container';

const HvorforVegetar: React.FunctionComponent = () => {
    return (
        <Container className="mt-16">
            <h2 className="mb-4">Hvorfor skal man overveje at blive vegetar?</h2>
            <hr className="w-48 mb-4 border-2" />
            <p className="text-gray-900 mt-1 mb-3">
                Der er rigtig mange gode grunde til at blive vegetar. Du skal dog altid overveje seriøst om det noget for dig.
                Ikke alle opnår glæde ved at skift. Vi præsenterer her nogle af de populære årsager til, at folk bliver vegetarer.
            </p>
            <h5>Du mindsker beslastningen på klimaet</h5>
            <p className="text-gray-900 mt-1 mb-3">
                Generelt er klimadebatten meget kompliceret, da der er mange aspekter involveret.
                De fleste undersøgelser peger dog i retning af, at kød (specielt oksekød) beslaster klimaet mere end vegetariske råvarer.
                Du kan derfor være med til at hjælpe klimaet ved at skifte til en vegetarisk kost.
            </p>
            <h5>Det er lettere at mindske kalorieindtaget</h5>
            <p className="text-gray-900 mt-1 mb-3">
                Med en vegetarisk kost får du typisk flere fibre og færre kalorier.
                Dette er rigtig godt i din kost, hvis du gerne vil tabe dig.
                Undersøgelser viser, at danskere har lettere ved at holde sig slanke med en vegetarisk kost.
            </p>
        </Container>
    );
}

export default HvorforVegetar;