
//Dimensions define as a string "1920x1080"
const imageResize =  (url : string, dimensions : string , smartCropping : boolean = true) : string => {

    //To access storyblok image service, the following endpoint has to be used
    const storyblokImageServiceEndpoint = "https://img2.storyblok.com/" 

    //Regex to find Everything after https://a.storyblok.com/, this will be needed for URL reconstruction
    let re = new RegExp(/https:\/\/a.storyblok.com(.*)/)
    let match = re.exec(url);

    if (match === null) {
      return url;
    }

    //Reconstructing URL with dimensions and smart cropping
    if(smartCropping == true) {
      return storyblokImageServiceEndpoint + dimensions + "/smart/" + match[1]
    }
    else{
      return storyblokImageServiceEndpoint + dimensions + match[1]
    }
    
}

export const ImageResizeService = {
    imageResize
  }