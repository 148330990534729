import * as React from 'react';
import Container from '../layout/Container';

const VegetarDk: React.FunctionComponent = () => {
    return (
        <Container className="mt-16">
            <h2>Vegetar - hvilke opskrifter kan du finde?</h2>
            <p className="text-gray-900 mt-1 mb-3">
                Her på siden kan du finde en lang række gode vegetariske opskrifter.
                Denne side er startet for at lave et vegetarisk univers, hvor en lang række bloggere og kokke har mulighed for dele deres opskrifter.
            </p>
            <p className="text-gray-900 mt-1 mb-3">
                I øjeblikket arbejder vi med en række kokke, som alle giver deres bidrag til opskriftsuniverset.
                Vi har ingen klare definitioner for opskrifterne udover, at de selvfølgelig skal være vegetariske.
                Du kan også finde opskrifter på vegansk mad, hvis du er veganer.
            </p>
            <p className="text-gray-900 mt-1 mb-3">
                Opskrifterne spænder fra små lækre snacks til hovedretter, som du kan bruge i den dagligdag.
                Du får udførlige beskrivelser samt gode billeder af hver ret, så du let kan lave dem i dit eget køkken.
                Desuden har du muligheden for at søge mellem alle opskrifterne, så du hurtigt kan finde nogle med udvalgte ingredienser.
            </p>
        </Container>
    );
}

export default VegetarDk;